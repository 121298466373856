// Save cookie to browser with given details
export const setCookie = (cookieName, cookieValue, expDays) => {
  const d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

// Check if cookie 'cookieNotification' exists
export const checkCookie = () => {
  const cookie = 'cookieNotification';
  const cookieList = document.cookie.split(';');
  const cookieExist = !!cookieList.find(
    element => element.trim().indexOf(cookie) === 0
  );

  return cookieExist;
};
