const initialState = {
  history: [],
  tags: []
};

export default (state, action) => {
  const currentState = state || initialState;

  switch (action.type) {
    case 'SET_HISTORY': {
      return {
        ...currentState,
        history: action.payload
      };
    }
    case 'SET_TAGS': {
      return {
        ...currentState,
        tags: action.payload
      };
    }
    default: {
      return currentState;
    }
  }
};
