const initialState = {
  scrollPosition: 0,
  shrinkLogo: false
};

export default (state, action) => {
  const currentState = state || initialState;

  switch (action.type) {
    case 'SAVE_SCROLL_POSITION': {
      return {
        ...currentState,
        scrollPosition: action.payload
      };
    }
    case 'CLEAR_SCROLL_POSITION': {
      return {
        ...currentState,
        scrollPosition: 0
      };
    }
    case 'SHRINK_LOGO': {
      const size = !currentState.shrinkLogo;
      return {
        ...currentState,
        shrinkLogo: size
      };
    }
    default: {
      return currentState;
    }
  }
};
