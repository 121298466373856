export const saveScrollPosition = value => ({
  type: 'SAVE_SCROLL_POSITION',
  payload: value
});

export const clearScrollPosition = () => ({
  type: 'CLEAR_SCROLL_POSITION'
});

export const shrinkLogo = () => ({
  type: 'SHRINK_LOGO'
});
