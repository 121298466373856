// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-covid-selector-business-js": () => import("./../../../src/pages/covid-selector-business.js" /* webpackChunkName: "component---src-pages-covid-selector-business-js" */),
  "component---src-pages-covid-selector-js": () => import("./../../../src/pages/covid-selector.js" /* webpackChunkName: "component---src-pages-covid-selector-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-index-js": () => import("./../../../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-templates-podcast-episode-js": () => import("./../../../src/templates/PodcastEpisode.js" /* webpackChunkName: "component---src-templates-podcast-episode-js" */)
}

