import { combineReducers } from 'redux';
import cookieReducer from './cookieReducer';
import selectorReducer from './selectorReducer';
import menuReducer from './menuReducer';
import pageReducer from './pageReducer';

export default combineReducers({
  cookieFab: cookieReducer,
  selector: selectorReducer,
  menu: menuReducer,
  page: pageReducer
});
