import React from 'react';
import { Provider } from 'react-redux';
import createStore from './src/state/createStore';
import { checkCookie } from './src/utils/Cookie';
import {
  showCookieFab,
  hideCookieFab
} from './src/state/actions/cookieActions';
import { clearScrollPosition } from './src/state/actions/pageActions';
import { closeAllMenus } from './src/state/actions/menuActions';

const store = createStore();

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

export const onRouteUpdate = () => {
  store.dispatch(clearScrollPosition());
  store.dispatch(closeAllMenus());

  // Check cookie to set Cookie Fab visibility
  if (checkCookie()) {
    store.dispatch(hideCookieFab());
  } else {
    store.dispatch(showCookieFab());
  }
};
