const initialState = {
  cookieFabVisible: false
};

export default (state, action) => {
  const currentState = state || initialState;

  switch (action.type) {
    case 'SHOW_COOKIE_FAB': {
      return {
        ...currentState,
        cookieFabVisible: true
      };
    }
    case 'HIDE_COOKIE_FAB': {
      return {
        ...currentState,
        cookieFabVisible: false
      };
    }
    default: {
      return currentState;
    }
  }
};
