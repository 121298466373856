const initialState = {
  mobileMenu: {
    open: false
  },
  mobileMenusOpen: false
};

export default (state, action) => {
  const currentState = state || initialState;

  switch (action.type) {
    case 'TOGGLE_MOBILE_MENU': {
      const open = !currentState.mobileMenu.open;
      return {
        ...currentState,
        mobileMenu: {
          ...currentState.mobileMenu,
          open
        },
        mobileMenusOpen: open
      };
    }
    case 'CLOSE_ALL_MENUS': {
      return {
        ...currentState,
        mobileMenu: initialState.mobileMenu,
        mobileMenusOpen: false
      };
    }
    default: {
      return currentState;
    }
  }
};
