/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux';
import rootReducer from './reducers/rootReducer';

export default () => {
  const windowGlobal = typeof window !== 'undefined' && window;

  return createStore(
    rootReducer,
    windowGlobal &&
      windowGlobal.__REDUX_DEVTOOLS_EXTENSION__ &&
      windowGlobal.__REDUX_DEVTOOLS_EXTENSION__()
  );
};
